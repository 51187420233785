import React, { useEffect } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { Container } from "reactstrap"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
// import google from "../../images/google-review.png"

const TechTestimonials = ({ data }) => {
  console.log("ddd", data)
  const testimonials = data.allSanityTestimonials.nodes

  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.async = true
    script.defer = true
    document.head.appendChild(script)

    return () => {
      // Cleanup: remove the script when the component is unmounted
      document.head.removeChild(script)
    }
  }, [])

  return (
    <Layout title="Testimonials">
      <SEO
        title="Testimonials"
        description="Simply put, it’s a network of professionals and business services that enables us to provide the best possible service to our candidates and clients."
      />
      <div id="ts-page">
        <div className="page-headline">
          <Container>
            <h1 className="bottom-underline text-blue text-center">
              TESTIMONIALS
            </h1>
            {/* <div className="google-review-image text-center mx-auto my-5">
              <img className="w-75 img-fluid mx-auto" src={google} alt="" />
            </div> */}

            <div className="">
              <div className="google-review home-review">
                <div
                  className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
                  data-elfsight-app-lazy
                  // data-embed-id="25369363"
                ></div>
                {/* <div className="overlay" /> */}
              </div>
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
              >
                <Masonry>
                  {testimonials.map((test, index) => (
                    <div key={index} className="testimonial-mason">
                      <ul
                        className={`list-unstyled text-right ratings-${
                          test.rating ? test.rating : 5
                        }`}
                      >
                        <li className="d-inline">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li className="d-inline">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li className="d-inline">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li className="d-inline">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                        <li className="d-inline">
                          <i className="fa fa-star" aria-hidden="true"></i>
                        </li>
                      </ul>
                      <p>{test.Message}</p>
                      <h6 className="mb-0" style={{ color: "#176588" }}>
                        {test.name},
                      </h6>
                      <p style={{ color: "#176588" }}>{test.designation}</p>
                    </div>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default TechTestimonials

export const techtestQuery = graphql`
  query techtestQuery {
    allSanityTestimonials {
      nodes {
        id
        name
        rating
        Message
        designation
      }
    }
  }
`
